
import { Vue, Component } from 'vue-property-decorator';
import { getTfseCommunityList } from '@/api/tfse';
import Pagination from '@/components/Pagination/index.vue';

@Component({
	components: {
		Pagination,
	},
})
export default class extends Vue {
	mounted() {
		this.getTfseCommunityList();
	}

	private listQuery = {
		communitySearch: true,
		page: 0,
		size: 5,
	};

	private loading = true;

	private totalElements = 0;

	private tfseList: any = [];

	private getTfseCommunityList() {
		this.loading = true;
		getTfseCommunityList(this.listQuery).then((res) => {
			this.loading = false;
			this.tfseList = res.data.content;
			this.totalElements = res.data.totalElements;
		});
	}
}
